import React from "react";

const VirtualEventFormPlaceholder = ({ btnText = "Submit" }) => {
  return (
    <form className="hs-form-private hs-form stacked">
      <fieldset className="form-columns-2">
        <div className="hs_firstname hs-firstname hs-fieldtype-text field hs-form-field">
          <label
            id="label-firstname-dee1c87e-1e61-4fbc-bd60-16c1ac5a12ed"
            placeholder="Enter your First name"
            htmlFor="firstname-dee1c87e-1e61-4fbc-bd60-16c1ac5a12ed"
          >
            <span>First name</span>
            <span className="hs-form-required">*</span>
          </label>
          <div className="input">
            <input
              disabled
              id="firstname-dee1c87e-1e61-4fbc-bd60-16c1ac5a12ed"
              name="firstname"
              placeholder=""
              type="text"
              className="hs-input"
              inputMode="text"
              autoComplete="given-name"
              value=""
            />
          </div>
        </div>
        <div className="hs_lastname hs-lastname hs-fieldtype-text field hs-form-field">
          <label
            id="label-lastname-dee1c87e-1e61-4fbc-bd60-16c1ac5a12ed"
            placeholder="Enter your Last name"
            htmlFor="lastname-dee1c87e-1e61-4fbc-bd60-16c1ac5a12ed"
          >
            <span>Last name</span>
            <span className="hs-form-required">*</span>
          </label>

          <div className="input">
            <input
              disabled
              id="lastname-dee1c87e-1e61-4fbc-bd60-16c1ac5a12ed"
              name="lastname"
              placeholder=""
              type="text"
              className="hs-input"
              inputMode="text"
              autoComplete="family-name"
              value=""
            />
          </div>
        </div>
      </fieldset>
      <fieldset className="form-columns-1">
        <div className="hs_email hs-email hs-field type-text field hs-form-field">
          <label
            id="label-email-dee1c87e-1e61-4fbc-bd60-16c1ac5a12ed"
            className=""
            placeholder="Enter your Work email"
            htmlFor="email-dee1c87e-1e61-4fbc-bd60-16c1ac5a12ed"
          >
            <span>Work email</span>
            <span className="hs-form-required">*</span>
          </label>

          <div className="input">
            <input
              disabled
              id="email-dee1c87e-1e61-4fbc-bd60-16c1ac5a12ed"
              name="email"
              required=""
              placeholder=""
              type="email"
              className="hs-input w-full"
              inputMode="email"
              autoComplete="email"
              value=""
            />
          </div>
        </div>
      </fieldset>
      <fieldset className="form-columns-1">
        <div className="hs_jobtitle hs-jobtitle hs-fieldtype-text field hs-form-field">
          <label
            id="label-jobtitle-dee1c87e-1e61-4fbc-bd60-16c1ac5a12ed"
            className=""
            placeholder="Enter your Job title"
            htmlFor="jobtitle-dee1c87e-1e61-4fbc-bd60-16c1ac5a12ed"
          >
            <span>Job title</span>
          </label>

          <div className="input">
            <input
              disabled
              id="jobtitle-dee1c87e-1e61-4fbc-bd60-16c1ac5a12ed"
              name="jobtitle"
              type="text"
              className="hs-input w-full"
              inputMode="text"
              autoComplete="organization-title"
            />
          </div>
        </div>
      </fieldset>
      <fieldset className="form-columns-1">
        <div className="hs_what_questions_do_you_have_for_eric_and_victor_ hs-what_questions_do_you_have_for_eric_and_victor_ hs-fieldtype-textarea field hs-form-field">
          <label
            id="label-what_questions_do_you_have_for_eric_and_victor_-dee1c87e-1e61-4fbc-bd60-16c1ac5a12ed"
            placeholder="Enter your What questions do you have for Eric and Victor?"
          >
            <span>What questions do you have for Eric and Victor?</span>
          </label>
          <div className="input">
            <textarea
              id="what_questions_do_you_have_for_eric_and_victor_-dee1c87e-1e61-4fbc-bd60-16c1ac5a12ed"
              className="hs-input hs-fieldtype-textarea"
              name="what_questions_do_you_have_for_eric_and_victor_"
              placeholder=""
            />
          </div>
        </div>
      </fieldset>
      <div className="hs_submit hs-submit">
        <div className="actions">
          <input
            disabled
            type="submit"
            className="hs-button primary large"
            value="Submit"
          />
        </div>
      </div>
    </form>
  );
};

export default VirtualEventFormPlaceholder;
